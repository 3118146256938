<template>
  <div class="moreContent_cont">
    <div class="moreContent_container">
      <div class="moreContent_chevron"></div>
      <div class="moreContent_chevron"></div>
      <div class="moreContent_chevron"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* .moreContent_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: fit-content;
  background: transparent;
}

.moreContent_container {
  position: relative;
  width: 12px;
  height: 12px;
}

.moreContent_chevron {
  position: absolute;
  width: 14px;
  height: 4px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.moreContent_chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.moreContent_chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.moreContent_chevron:before,
.moreContent_chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: black;
}

.moreContent_chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.moreContent_chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(15px);
  }
  67% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(26px) scale3d(0.5, 0.5, 0.5);
  }
} */



.moreContent_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: fit-content;
  background: transparent;
}

.moreContent_container {
  position: relative;
  width: 26px;
  height: 16px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  background: white;
  /* border: 1px solid green; */
}

.moreContent_chevron {
  position: absolute;
  width: 14px;
  height: 2px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.moreContent_chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.moreContent_chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.moreContent_chevron:before,
.moreContent_chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 80%;
  width: 51%;
  background: var(--customA-801B1C);
}

.moreContent_chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.moreContent_chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(4px);
  }
  67% {
    opacity: 1;
    transform: translateY(8px);
  }
  100% {
    opacity: 0;
    transform: translateY(12px) scale3d(0.5, 0.5, 0.5);
  }
}
</style>