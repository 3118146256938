<template>
  <div
    class=""
    :class="elementosDom.tieneError ? 'checkBoxAconError' : 'checkBoxAsinError'"
  >
    <div>
      <div class="radioBtnsA">
        <div class="radioBtnsATitle" @click="call_explicacion">
          <!-- {{ elementosDom.strPlaceHolder }}  -->
          {{t(`variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`)}}
          <img :src="interrogacion" alt="" />
        </div>
        <div class="radioBtsARadioCont">
          <label class="radioBtnsAcontainerA"
            >{{ t("app.comunes.btns.si") }}
            <input type="checkbox" v-model="estadoCheckSi" />
            <span
              class="radioBtnsAcheckmarkA"
              :class="
                elementosDom.tieneError
                  ? 'checkmarkAconError'
                  : 'checkmarkAsinError'
              "
            ></span>
          </label>

          <label class="radioBtnsAcontainerA"
            >{{ t("app.comunes.btns.no") }}
            <input type="checkbox" v-model="estadoCheckNo" />
            <span
              class="radioBtnsAcheckmarkA"
              :class="
                elementosDom.tieneError
                  ? 'checkmarkAconError'
                  : 'checkmarkAsinError'
              "
            ></span>
          </label>
        </div>
      </div>

      <div class="radioBtnsAText">
        {{ t(elementosDom.claseIcon) }}
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "radioBtnsAzulComp",
  props: {
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
  },
  data() {
    return {
      estadoCheckSi: false,
      estadoCheckNo: false,
      estadoCheck: null,
      interrogacion: require("../../assets/imgs/interrogacion.png"),
      prefijoI18n: "",
    };
  },
  methods: {
    call_valida_basico() {
      this.$emit("valida_basico_", {
        variable: this.elementosDom.variable,
        valida: this.variable != "" ? true : false,
      });
    },
    call_explicacion() {
      this.$emit("call_explicacion", "cargo_jerarquico");
    },

    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
  },
  watch: {
    estadoCheckSi() {
      if (this.estadoCheckSi) {
        this.estadoCheck = "Si";
        this.estadoCheckNo = false;
        // console.log("this.estadoCheckSi", this.estadoCheckSi, this.estadoCheckNo, this.estadoCheck)
      }else{
        if(!this.estadoCheckNo) this.estadoCheck = null;
        // this.estadoCheckNo = false;
      }
    },
    estadoCheckNo() {
      if (this.estadoCheckNo) {
        this.estadoCheck = "No";
        this.estadoCheckSi = false;
        // console.log("this.estadoCheckNo", this.estadoCheckSi, this.estadoCheckNo, this.estadoCheck)
      }else{
        if(!this.estadoCheckSi) this.estadoCheck = null;
        // this.estadoCheckSi = false;
      }
    },
    estadoCheck() {
      let data = {
        variable: this.elementosDom.variable,
        indiceElementoDom: this.indiceElementoDom,
        clasetienedependiente: "",
        valor: this.estadoCheck,
        validaciones: this.elementosDom.validaciones,
        tipo_persona: "Remitente",
      };

      this.$emit("llamarCambioElemento", data);
      this.call_valida_basico();
    },
  },
  mounted() {
    // console.log(this.elementosDom);
    this.setPrefijoI18n();
    if (this.elementosDom.valor != "") {
      if (this.elementosDom.valor == "Si") {
        this.estadoCheckSi = true;
        this.estadoCheck = "Si";
        this.estadoCheckNo = false;
      } else if (this.elementosDom.valor == "No") {
        this.estadoCheckNo = true;
        this.estadoCheck = "No";
        this.estadoCheckSi = false;
      }
    }
    this.call_valida_basico();
  },
};
</script>

<style>

.radioBtnsA {
  display: flex;
  justify-content: space-between;
}
.radioBtnsATitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  display: inline-block;
}
.radioBtnsATitle img {
  width: 1rem;
  margin-left: 0.5rem;
  margin-top: -0.3rem;
  cursor: pointer;
}
.radioBtnsAText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.2rem;
  padding-right: 0.8rem;
}

.radioBtsARadioCont {
  display: flex;
  justify-content: center;
  margin: 0;
  line-height: 15px;
}

.radioBtnsAcontainerA {
  display: block;
  position: relative;
  padding-left: 1rem;
  padding-top: 1px;
  width: fit-content;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 1.5rem;
}

.radioBtnsAcontainerA input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radioBtnsAcheckmarkA {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: white;
  border-radius: 3px;
}

.radioBtnsAcontainerA input:checked ~ .radioBtnsAcheckmarkA {
  background-color: white;
}

.radioBtnsAcheckmarkA:after {
  content: "";
  position: absolute;
  display: none;
}

.radioBtnsAcontainerA input:checked ~ .radioBtnsAcheckmarkA:after {
  display: block;
}

.radioBtnsAcontainerA .radioBtnsAcheckmarkA:after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid var(--customC-614AD3);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>