<template>
  <div
    class="contenedorCustomSelect_tel"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <div @click.stop="editable ? (cambioEstado = !cambioEstado) : false">
      <div class="caja_entrada_sel_tel inicio">
        <div class="selectCustom_tel" :class="cambioEstado ? 'active' : ''">
          <div class="contenido-select_tel">
            <div class="caja_entrada_text_tel" v-if="textOpcionSelected != ''">
              {{ textOpcionSelected }}
            </div>
            <div class="input_tel_sel_cont caja_entrada_text_tel" v-else>
              <div>+&nbsp;</div>
              <input
                ref="input_prefijo_telefono"
                id="input_prefijo_telefono"
                class="input_tel_sel inputDinamicInputBox"
                v-model="prefijo"
                type="text"
                @keypress="isNumber($event)"
                @click.stop=""
                @blur="enviar_prefijo_custom"
                @keyup="max_leng_cod"
              />
            </div>
          </div>
        </div>

        <div class="opciones_tel" :class="cambioEstado ? 'active' : ''">
          <div class="opcionesCont">
            <div
              class="opcion"
              @click="actualizarDatos(op[0])"
              v-for="(op, ind) in remitente_telef_prefijo"
              :key="ind"
            >
              <div class="contenido-opcion_tel caja_entrada_text_tel">
                <div class="op_sel_tel_cod">{{ op[1] }}</div>
                <div class="op_sel_tel_pais">{{ op[2] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    prefijo_telef: { type: String },
    editable: Boolean,
  },
  data() {
    return {
      arrowHeadSelect: require("../../assets/imgs/arrowHeadSelect.svg"),
      arrowHeadSelectError: require("../../assets/imgs/arrowHeadSelectError.svg"),
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
      prefijoI18n: "Remitente",
      variable: "",
      textOpcionSelected: "",
      primera_carga: true,
      prefijo: "",
      input_ref: null,
    };
  },
  computed: {
    ...mapState(["remitente_telef_prefijo"]),
  },
  methods: {
    ...mapMutations([""]),

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    max_leng_cod() {
      if (this.prefijo.length > 3) {
        let aux = this.prefijo.substr(0, 3);
        this.prefijo = aux;
      }
    },

    cambiarType(evt) {
      this.$emit("cambiarType", evt);
      let evento = evt.type;
      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.stateInput = "post";
        this.inFocus = false;
        this.cambioEstado = false;
      }
    },

    enviar_prefijo_custom() {
      this.llamarCambioElemento(`+${this.prefijo}`);
    },

    llamarCambioElemento(data) {
      this.$emit("llamarCambioElemento", data);
    },

    actualizarDatos(valor) {
      let index = this.remitente_telef_prefijo.findIndex(
        (item) => item[0] == valor
      );
      if (index > -1) {
        this.textOpcionSelected = this.remitente_telef_prefijo[index][1];
      }

      this.variable = valor;
      this.llamarCambioElemento(this.textOpcionSelected);

      if (valor == "0") {
        setTimeout(() => {
          this.input_ref = this.$refs["input_prefijo_telefono"];

          this.input_ref.focus();
        }, 200);
      }
    },

    establecer_condi_inicial() {
      this.variable = "1";
      this.textOpcionSelected = this.prefijo_telef;

      let index = this.remitente_telef_prefijo.findIndex((item) => {
        return item[1] == this.prefijo_telef;
      });

      if (index > -1) {
        this.llamarCambioElemento(this.textOpcionSelected);
      } else if (this.prefijo_telef != "...") {
        this.llamarCambioElemento(this.prefijo_telef);
        this.prefijo = this.prefijo_telef.substring(1);
      } else {
        this.llamarCambioElemento(this.textOpcionSelected);
      }
    },
  },
  watch: {
    prefijo_telef() {
      this.establecer_condi_inicial();
    },
  },
  mounted() {
    this.establecer_condi_inicial();

    setTimeout(() => {
      this.primera_carga = false;
    }, 1000);
  },
  ionViewWillEnter() {},
};
</script>

<style >
.caja_entrada_sel_tel {
  height: 2.75rem;
  border-radius: 10px;
  padding: 0 0.6rem 0 0.4rem;
  display: flex;
  flex-direction: row;
  position: relative;
}

.caja_entrada_text_tel {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.5px;
  padding: 0.2rem 0;
}

.selectCustom_tel {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;

  z-index: 100;
}

.selectCustom_tel.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectCustom_tel.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.tituloCustomSelect_tel {
  font-family: "Roboto";
}
.opciones_tel {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 300;
  width: 10rem;
  display: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: absolute;
  margin-top: 1.25rem;
  margin-left: -5px;
}
.opciones_tel.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

.contenido-opcion_tel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: 0.2s ease all;
  cursor: pointer;
}

.placeHolderCustomSelect_tel {
  color: var(--customG-787878);
}
.contenido-select_tel {
  display: flex;
  justify-content: center;
}
.op_sel_tel_cod {
  width: 40%;
}
.input_tel_sel_cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.input_tel_sel_cont div {
  display: flex;
  justify-content: center;
}
.input_tel_sel {
  border-bottom: 1px solid gray;
  width: 2rem;
  background: white !important;
  border-radius: 5px 5px 0 0;
}
</style>