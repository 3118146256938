<template>
  <div>
    <div class="entradaLabelCont">
      <span v-show="variable != ''">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>
    <div
      class="caja_entrada_tel"
      :class="
        stateInput == 'noEditable'
          ? 'noEditable'
          : stateInput == 'inicio'
          ? 'inicio'
          : stateInput == 'onFocus'
          ? 'onFocus'
          : stateInput == 'post'
          ? 'post'
          : stateInput == 'onError'
          ? 'onError'
          : ''
      "
    >
      <select_tel
        class="select_tel_cont"
        :prefijo_telef="prefijo_telef"
        tabindex="0"
        @cambiarType="cambiarType"
        @llamarCambioElemento="set_prefijo_tel"
        :editable="false"
      />
      <!-- <select name="" id="">
            <option :value="prefijo[0]" v-for="(prefijo, i) in remitente_telef_prefijo" :key="i">{{prefijo[1]}} &nbsp;{{prefijo[2]}}</option>
        </select> -->

      <input
        :type="elementosDom.tipo"
        class="inputDinamicInputBox caja_entrada_text input_tel_separador"
        :class="stateInput == 'onError' ? 'error' : ''"
        :placeholder="`${t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )}*`"
        v-model="variable"
        @keypress="isNumber($event)"
        autocomplete="off"
        @focus="cambiarType($event)"
        @blur="cambiarType($event)"
        :ref="elementosDom.variable == 'numero_documento' ? 'rut' : ''"
        :disabled="stateInput == 'noEditable'"
        @keyup="call_valida_basico()"
      />
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{
          variable == "" ? mensajeError[0] : elementosDom.texto_ayuda
        }}</span>
      </div>
      <div
        :class="stateInput == 'onFocus' ? 'mensajeOkFocus' : 'mensajeOkBlur'"
        v-show="mensajeOk.mostrar"
      >
        {{ mensajeOk.texto }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import select_tel from "./select_tel.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    typeInput: { type: String },
    mensajeError: { type: Array },
    mensajeOk: { type: Object },
    error: { type: Boolean },
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
  },
  components: {
    select_tel,
  },
  data() {
    return {
      variable: "",
      stateInput: "inicio",
      ojo: require("../../assets/imgs/ojo.svg"),
      ojoCerrado: require("../../assets/imgs/ojoCerrado.svg"),
      interactuado: false,
      //   textoPassVisible: false,
      inFocus: false,
      prefijoI18n: "",
      prefijo_telef: "+56",
      var_prefijo: "",
    };
  },
  computed: {
    ...mapState(["remitente_telef_prefijo"]),
  },
  methods: {
    ...mapActions(["validarRut"]),

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    enviar_cambios() {
      let data = {
        elemento: this.elementosDom.identificador,
        accion: "noImporta",
        tipo_persona: this.elementosDom.tipo_persona,
      };

      data.indiceElementoDom = this.indiceElementoDom;
      data.clasetienedependiente = "";
      data.valor = `${this.var_prefijo}-${this.variable.trim()}`;
      this.$emit("llamarCambioElementoInput", data);
      //    console.log(data.valor);
    },

    async cambiarType(evt) {
      let evento = evt.type;

      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.inFocus = false;
        if (this.error) {
          this.stateInput = "onError";
        } else {
          this.stateInput = "post";
        }

        this.enviar_cambios();
      }

      this.interactuado = true;
    },
    set_prefijo_tel(prefijo) {
      this.var_prefijo = prefijo;
      this.enviar_cambios();
    },
    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
    call_valida_basico() {
      this.$emit("valida_basico_", {
        variable: this.elementosDom.identificador,
        valida: this.variable,
      });
      this.enviar_cambios();
    },
    set_initial_cond() {
      if (this.elementosDom.valor.length > 0) {
        let separador_index = this.elementosDom.valor.indexOf("-");
        if (separador_index > -1) {
          let telef_segmentos = this.elementosDom.valor.split("-");
          if (telef_segmentos.length == 2) {
            this.variable = telef_segmentos[1];
            // this.prefijo_telef = telef_segmentos[0];
            this.stateInput = "post";
          }
        }
      } else {
        // this.variable = "";
        // this.prefijo_telef = "...";
      }
      // this.variable = this.elementosDom.valor;
      this.call_valida_basico();
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
    },
    elementosDom() {
      // if (this.elementosDom.valor.length > 0) {
      //   let separador_index = this.elementosDom.valor.indexOf("-");
      //   if (separador_index > -1) {
      //     let telef_segmentos = this.elementosDom.valor.split("-");
      //     if (telef_segmentos.length == 2) {
      //       this.variable = telef_segmentos[1];
      //       this.prefijo_telef = telef_segmentos[0];
      //     }
      //   }
      // } else {
      //   this.variable = "";
      //   this.prefijo_telef = "...";
      // }
      this.set_initial_cond();

      this.call_valida_basico();
    },
  },
  created() {},
  mounted() {
    this.setPrefijoI18n();

    // if (this.elementosDom.tipo == "number") {
    //   this.variable = parseInt(this.elementosDom.valor);
    // } else {
    //   this.variable = this.elementosDom.valor;
    // }
    // if (this.elementosDom.editable == "no") this.stateInput = "noEditable";

    // console.log(this.elementosDom);
    // if (this.elementosDom.valor.length > 0) {
    //   let separador_index = this.elementosDom.valor.indexOf("-");
    //   if (separador_index > -1) {
    //     let telef_segmentos = this.elementosDom.valor.split("-");
    //     if (telef_segmentos.length == 2) {
    //       this.variable = telef_segmentos[1];
    //       // this.prefijo_telef = telef_segmentos[0];
    //     }
    //   }
    // } else {
    //   this.variable = "";
    //   // this.prefijo_telef = "...";
    // }
    // this.variable = this.elementosDom.valor;
    // this.call_valida_basico();

    this.set_initial_cond();
  },
};
</script>

<style >
/* .inputBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.ojo {
  width: 1rem;
  display: flex;
  justify-content: center;
}
</style>