export const blur_sup_scroll = (scrollTop) => {
    if (scrollTop > 0) {
        return false;
    } else {
        return true;
    }
}

export const blur_inf_scroll = (scrollTop, clientHeight, scrollHeight) => {
    if (scrollTop + clientHeight >= scrollHeight - 1) {
        return true;
    } else {
        return false;
    }
}

export const contenido_style = (heigthScreen, header, footer, nav_bar) => {
    let nav_bar_height = nav_bar ? nav_bar : 0;
    let clase = {
        top: `${header}px`,
        height: `${heigthScreen - (header + footer + nav_bar_height)}px`,
        ["padding-bottom"]: 1 + "rem",
    };
    return clase;
}

export const blur_sup_style = (header) => {
    return { top: `${header}px` };
}


export const blur_inf_style = (footer) => {
    return { bottom: `${footer}px` };
}

export const formato_alargado = (header, heigthScreen, footer, contenido_regisFormInicial_height) => {
    let aux = false;
    if(contenido_regisFormInicial_height && (heigthScreen < (header + footer + contenido_regisFormInicial_height + 30)) ){
      aux = true;
    }
    return aux;
  }