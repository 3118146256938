<template>
  <div
    class="contenedorCustomSelect"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <div class="entradaLabelCont">
      <span v-if="Object.keys(itemSelected).length > 0">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>
    <!-- focoFuera();  -->
    <div>
      <div
        class="caja_entrada"
        :class="
          stateInput == 'noEditable'
            ? 'noEditable'
            : stateInput == 'inicio'
            ? 'inicio'
            : stateInput == 'onFocus'
            ? 'onFocus'
            : stateInput == 'post'
            ? 'post'
            : stateInput == 'onError'
            ? 'onError'
            : ''
        "
      >
        <div
          class="selectCustom"
          :class="cambioEstado ? 'active' : ''"
          @click="
            elementosDom.editable == 'si'
              ? (cambioEstado = !cambioEstado)
              : null
          "
        >
          <div class="contenido-select">
            <span
              class="placeHolderCustomSelect caja_entrada_text"
              v-if="itemSelected.length == 0"
              >{{
                prefijoI18n.length > 0 && elementosDom.variable
                  ? t(
                      `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
                    )
                  : ""
              }}
            </span>
            <span class="tituloCustomSelect caja_entrada_text" v-else>{{
              itemSelected.indice
                ? t(
                    `variables['${elementosDom.variable}']['${itemSelected.indice}']`
                  )
                : ""
            }}</span>
          </div>
          <div><img :src="arrowHeadSelect" alt="" /></div>
        </div>

        <div class="opciones" :class="cambioEstado ? 'active' : ''">
          <div class="opcionesCont">
            <div
              class="opcion"
              @click="setCambioSelect(op)"
              v-for="(op, ind) in elementosDom.opciones"
              :key="ind"
            >
              <div class="contenido-opcion">
                <div class="textos">
                  <span class="tituloCustomSelect caja_entrada_text_opcion">{{
                    t(`variables['${elementosDom.variable}']['${op.indice}']`)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <input type="hidden" name="pais" id="inputSelect" value="" /> -->
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{ mensajeError }}</span>
      </div>
      <div class="mensajeOkFocus">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
    mostrarLabel: { type: Boolean },
    mensajeError: { type: String },
    error: { type: Boolean },
  },
  data() {
    return {
      arrowHeadSelect: require("../../assets/imgs/arrowHeadSelect.svg"),
      arrowHeadSelectError: require("../../assets/imgs/arrowHeadSelectError.svg"),
      itemSelected: [],
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
      prefijoI18n: "",
    };
  },
  computed: {
    ...mapState([]),
  },
  methods: {
    ...mapMutations([""]),
    // focoFuera() {
    //   this.cambioEstado = false;
    // },
    setCambioSelect(item) {
      if (this.elementosDom.editable == "no") {
        return;
      }
      this.cambioEstado = !this.cambioEstado;
      this.itemSelected = item;
    },
    cambiarType(evt) {
      if (this.elementosDom.editable == "no") {
        return;
      }
      let evento = evt.type;
      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.stateInput = "post";
        this.inFocus = false;
        this.cambioEstado = false;
        // this.$emit("dispatchChangeData", this.itemSelected);
      }
    },
    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
    call_valida_basico() {
      this.$emit("valida_basico_", {
        variable: this.elementosDom.variable,
        valida: this.variable != "" ? true : false,
      });
    },
  },
  watch: {
    // data(){
    //     this.itemSelected = [];
    // },
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
      // console.log(this.error);
    },
    itemSelected() {
      // console.log(this.itemSelected);
      if (Object.keys(this.itemSelected).length > 0) {
        let data = {
          tipo_persona: this.elementosDom.tipo_persona,
          variable: this.elementosDom.variable,
          valor: this.itemSelected.indice,
        };
        data.indiceElementoDom = this.indiceElementoDom;

        this.$emit("llamarPoblarOpcionesDir", data);
        this.call_valida_basico();
      }
    },
    elementosDom() {
      if (
        Object.keys(this.elementosDom).length > 0 &&
        this.elementosDom.opciones
      ) {
        let aux = this.elementosDom.opciones.findIndex((item) => {
          return item.selectTxt == "selected";
        });
        if (aux == -1 && this.itemSelected != []) {
          this.itemSelected = [];
        } else if (this.elementosDom.opciones == 1) {
          this.itemSelected = this.elementosDom.opciones[0];
          this.stateInput = "post";
        }
      }
    },
  },
  mounted() {
    if (Object.keys(this.elementosDom).length > 0) {
      this.setPrefijoI18n();
      // console.log("+++??+++", this.elementosDom);
      let aux = -1;
      if (this.elementosDom.opciones && this.elementosDom.opciones.length > 0) {
        aux = this.elementosDom.opciones.findIndex((item) => {
          return item.selectTxt == "selected";
        });
      }
      if (aux > -1) {
        this.itemSelected = this.elementosDom.opciones[aux];
        this.stateInput = "post";
      } else if (this.elementosDom.opciones == 1) {
        this.itemSelected = this.elementosDom.opciones[0];
        this.stateInput = "post";
      }
      if (this.elementosDom.editable == "no") this.stateInput = "noEditable";
      // console.log(this.elementosDom);
      this.call_valida_basico();
    }
  },
};
</script>

<style >
/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
/* .selectBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.selectCustom {
  /* background: #fff; */
  width: 100%;
  /* border-radius: 5px; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;
  position: relative;
  z-index: 100;
  /* border: 1.5px solid transparent; */
}

/* .selectCustom.active,
.selectCustom:hover {
  border: 1.5px solid #213f8f;
} */
.selectCustom.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectCustom.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.tituloCustomSelect {
  font-family: "Roboto";
  /* font-style: normal;
  font-weight: 500;
  line-height: 11px; */
  /* color: var(--customG-787878); */
  /* font-weight: 600;
  font-size: 0.5625rem; */
}

/* .opciones {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 300;
  width: 101%;
  display: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: absolute;
  margin-top: -1.25rem;
  margin-left: -1px;
} */

/* .option{
    cursor: pointer;
} */

/* .opcionesCont {
  background-color: #fff;
  overflow-y: auto;
  max-height: 150px;
}

.opcionesCont::-webkit-scrollbar {
  width: 4px;
  height: 90%;
  max-height: 90%;
  margin-top: auto;
  margin-bottom: auto;
}

.opcionesCont::-webkit-scrollbar-track {
  background-color: var(--customF-C4C4C4);
  border-radius: 10px;
}

.opcionesCont::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--customA-801B1C);
  max-height: 2rem;
} */

.opciones.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

/* .contenido-opcion {
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s ease all;
  cursor: pointer;
} */

.placeHolderCustomSelect {
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5625rem;
  line-height: 11px; */
  color: var(--customG-787878);
}
.contenido-select {
  display: flex;
  justify-content: center;
}

.caja_entrada_text_opcion {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.5rem;
  letter-spacing: 0.5px;
}
</style>