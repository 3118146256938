<template>
    <div class="pasosGiroCont">
        <img v-for="pasito in parseInt(totales)" :key="pasito" :src="paso == pasito ? iconPasoGiroCompletado : iconPasoGiroPendiente" alt="" srcset="">
    </div>
</template>

<script>
// import { mapState } from 'vuex';
export default {
    props:{
        paso: Number,
        totales: Number
    },
    data(){
        return{
            iconPasoGiroCompletado: require("../../assets/imgs/iconPasosGiroCompletado.svg"),
            iconPasoGiroPendiente: require("../../assets/imgs/iconPasosGiroPendiente.svg"),
        }
    },
    computed:{
       
    },
    mounted(){
        
    }
}
</script>

<style>
.pasosGiroCont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.pasosGiroCont img{
    margin: 0 0.15rem;
    padding: 0;
    height: fit-content;
    width: fit-content;
}
</style>