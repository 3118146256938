<template>
  <div class="envioSeguroText">
    <span>{{ t("app.comunes.envios_seguros_t1") }}&nbsp;</span>
    <span class="textUnderLine envioSeguroTextDestacado" @click="callEnvioSeguroModal">{{
      t("app.comunes.envios_seguros_t2")
    }}</span>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
    setup() {
    const { t } = useI18n();
    return { t };
  },
  methods:{
    callEnvioSeguroModal(){
        this.$emit("callEnvioSeguroModal_")
    }
  }
};
</script>

<style>
.envioSeguroText{
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-size: 0.6875rem;
    line-height: 0.8125rem;
    text-align: center;
    color: #787878;
}
.envioSeguroTextDestacado{
    font-weight: 700;
}
</style>