<template>
  <div class="explicarContainer">
    <!-- :class="ancho == '300' ? 'ancho_300' : ancho == '280' ? 'ancho_280' : 'ancho_250'" -->
    <div class="wrapper">
      <div class="explicarCerrar">
        <headerCerrar @cancelar="cerrarAlertModal" :colorIcon="colorIcon" />
      </div>
      <div
        class="explicarTitle title"
        :class="
          color_title == 'rojo'
            ? 'rojo'
            : color_title == 'azul'
            ? 'azul'
            : 'gris'
        "
      >
        {{ title }}
      </div>
      <div class="explicarText text_basico_sm f_w_400" v-if="text != ''">{{ text }}</div>
      <!-- <div class="explicarText" v-else>{{text}}</div> -->
      <!-- <div class="explicarBtn_separador"></div> -->
      <div class="explicarBtn" v-if="btnText_1 != ''">
        <btnAction
          :textBtn="btnText_1"
          :disabled="false"
          @action="call_action_1"
          :colorBack="color_btn_1"
          colorText="blco"
          estadoBtn="active"
        />
      </div>
      <div class="explicarBtn" v-if="btnText_2 != ''">
        <btnAction
          :textBtn="btnText_2"
          :disabled="false"
          @action="call_action_2"
          :colorBack="color_btn_2"
          colorText="blco"
          estadoBtn="active"
        />
      </div>
    </div>
  </div>
</template>

<script>
import btnAction from "../../components/generales/btnAction.vue";
import headerCerrar from "../../components/generales/headerCerrar.vue";
import { modalController } from "@ionic/vue";
import { mapMutations } from "vuex";


export default {
  props: {
    title: String,
    color_title: String,
    color_btn_1: String,
    color_btn_2: String,
    text: String,
    btnText_1: String,
    btnText_2: String,
    colorIcon: String,
  },
  components: {
    btnAction,
    headerCerrar,
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),
    
    cerrarAlertModal() {
      modalController.dismiss();
    },
    call_action_1() {
      this.setshowSpinner(false);
      modalController.dismiss({ action: "action_1" });
    },
    call_action_2() {
      this.setshowSpinner(false);
      modalController.dismiss({ action: "action_2" });
    },
  },
};
</script>

<style>
.explicarContainer {
  background: white;
  /* height: 100%;
  width: 100%; */
  display: flex;
  justify-content: center;
  padding: 1.5rem;
}
.rojo {
  color: var(--customA-801B1C);
}
.gris {
  color: var(--customH-4C4C4C);
}
.azul {
  color: var(--customC-614AD3);
}
/* .ancho_300{
  padding: 1.125rem 1.875rem 2.375rem 1.875rem;
}
.ancho_280{
  padding: 1.125rem 2.5rem 2.375rem 2.5rem;
}
.ancho_250{
  padding: 1.125rem 3.4rem 2.375rem 3.4rem;
} */

/* .explicarCerrar{
  margin-top: 1.5rem;
} */
.explicarTitle {
  margin-top: 2rem;

  text-align: center;
  /* color: #801b1c; */
}

.explicarText {
  margin-top: 1.7rem;

  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px; */
  /* or 164% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #787878;
}
.explicarBtn_separador {
  min-height: 2rem;
  height: 2rem;
}
.explicarBtn {
  margin-top: 2.5rem;
}
.text_basico_sm {
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0.5px;
}



/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px){
  /* .explicarContainer{
    width: 60%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  } */
  .text_basico_sm {
  font-style: normal;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.5px;
}
}

</style>