<template>
  <div class="headerBackCont"><img :src="iconBack" alt="" srcset="" @click="irBack"></div>
</template>

<script>
export default {
    name:'headerBack',
    data(){
        return{
            iconBack: require("../../assets/imgs/iconBack.svg"),
        }
    },
    methods:{
      irBack(){
        this.$emit("back");
      }
    }
};
</script>

<style>
.headerBackCont {
  display: flex;
  flex-direction: row-reverse;
  /* margin-top: 1rem;
  margin-right: 1rem; */
}
.headerBackCont img{
    cursor: pointer;
}
</style>