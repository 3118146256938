<template>
  <ion-page>
    <ion-content fullscreen="true">
      <div class="big_container">
        <div class="barra_nav" v-if="plataforma == 'web'">
          <navBar />
        </div>
        <div class="app_wide_centro" :style="main_cont_style">
          <div class="app_cont_app">
            <slot />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import navBar from "./navBar.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    ancho_app: String,
  },
  name: "screen_width_cont",
  components: {
    IonContent,
    IonPage,
    navBar,
  },
  data() {
    return {
      logo: require("../../assets/imgs/logo.svg"),
      iconSend: require("../../assets/imgs/iconTabSend.svg"),
      iconNuevo: require("../../assets/imgs/iconTabNuevo.svg"),
      iconCuenta: require("../../assets/imgs/iconTabCuenta.svg"),
      iconInfo: require("../../assets/imgs/iconTabInfo.svg"),
      iconoHome: require("../../assets/imgs/iconoHome.svg"),
    };
  },
  computed: {
    ...mapState([
      "widthScreen",
      "heigthScreen",
      "procesoGeneral",
      "idiomaPreferencia",
      "plataforma",
      "url_base_web",
    ]),

    main_cont_style() {
      let style = {
        height: "100%",
      };
      if (this.plataforma == "web") {
        style = {
          height: "calc(100vh - 4rem)",
        };
      }
      return style;
    },
  },
  methods: {
    ...mapMutations([""]),

    go_home_web() {
      window.location.replace(
        `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
      );
      // if (location.protocol == "http:") {
      //   window.location.replace(
      //     `http://localhost:3500/${this.idiomaPreferencia.cod}/`
      //   );
      // } else if (location.protocol == "https:") {
      //   window.location.replace(
      //     `https://localhost:3500/${this.idiomaPreferencia.cod}/`
      //   );
      // }
    },
  },
  mounted() {},
};
</script>

<style>
.big_container {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.barra_nav {
  height: 3.93rem;
}
.app_wide_centro {
  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.app_cont_app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}
</style>