<template>
  <div class="navCont" :style="nav_bar_height">
    <div class="navLogo clickable">
      <img :src="back" alt="" srcset="" @click="go_web_page" class="img_back" />
      <img :src="logo" alt="" srcset="" @click="go_web_page" class="img_logo" />
    </div>
    <div class="navMenu">
      <div class="navMenuHorizontal"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "navBar",
  components: {},
  data() {
    return {
      logo: require("../../assets/imgs/logo.svg"),
      back: require("../../assets/imgs/menuSp/back.svg"),
      nav_btn: require("../../assets/imgs/nav_btn.svg"),
      toggleHamburgerMenu: false,
      protocolo: "",
    };
  },
  computed: {
    ...mapState([
      "foco_general",
      "plataforma",
      "idiomaPreferencia",
      "nav_bar_height",
      "url_base_web",
    ]),
  },
  methods: {
    ...mapMutations(["updateFocoGeneral", "set_to_web"]),

    switch_menu() {
      this.updateFocoGeneral(false);
      this.toggleHamburgerMenu = !this.toggleHamburgerMenu;
    },
    go_web_page() {
      this.set_to_web(true);
      console.log("plataforma", this.plataforma);
      if (this.plataforma == "web") {
        
        window.location.replace(
            `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
          );
       
      }
    },
  },
  watch: {
    foco_general() {
      if (this.foco_general) {
        this.toggleHamburgerMenu = false;
      }
    },
  },
  mounted() {
    this.protocolo = location.protocol;
  },
};
</script>

<style>
.navCont {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  background: #fbfcfd;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.5rem;
}
.navLogo {
  width: 9rem;
  padding-left: 0.9375rem;
}
.img_back {
  width: 0.7rem;
}
.img_logo {
  width: 5.8125rem;
  padding-left: 0.8rem;
}

.navBtn {
  display: block;
}

.navMenuHorizontal {
  position: relative;
  display: none;
}
.navMenuVertival {
  position: relative;
  display: block;
}
</style>
