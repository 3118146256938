<template>
  <div
    class="contenedorCustomSelect"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <div class="entradaLabelCont">
      <span v-if="variable != ''">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>

    <div>
      <div
        class="caja_entrada"
        :class="
          stateInput == 'noEditable'
            ? 'noEditable'
            : stateInput == 'inicio'
            ? 'inicio'
            : stateInput == 'onFocus'
            ? 'onFocus'
            : stateInput == 'post'
            ? 'post'
            : stateInput == 'onError'
            ? 'onError'
            : ''
        "
      >
        <div
          class="selectCustom"
          :class="cambioEstado ? 'active' : ''"
          @click="
            elementosDom.editable == 'si'
              ? (cambioEstado = !cambioEstado)
              : null
          "
        >
          <div class="contenido-select">
            <span
              class="placeHolderCustomSelect caja_entrada_text"
              v-if="variable == ''"
              >{{
                t(
                  `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
                )
              }}
            </span>
            <span class="tituloCustomSelect caja_entrada_text" v-else>{{
              textOpcionSelected
            }}</span>
          </div>
          <div><img :src="arrowHeadSelect" alt="" /></div>
        </div>

        <div class="opciones" :class="cambioEstado ? 'active' : ''">
          <div class="opcionesCont">
            <div
              class="opcion"
              @click="actualizarDatos(op.value)"
              v-for="(op, ind) in elementosDom.elementos"
              :key="ind"
            >
              <div class="contenido-opcion">
                <div class="textos">
                  <span class="tituloCustomSelect caja_entrada_text">{{
                    op.text
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{ mensajeError }}</span>
      </div>
      <div class="mensajeOkFocus">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
    mostrarLabel: { type: Boolean },
    mensajeError: { type: String },
    error: { type: Boolean },
    editando: Boolean,
    registrando: Boolean,
  },
  data() {
    return {
      arrowHeadSelect: require("../../assets/imgs/arrowHeadSelect.svg"),
      arrowHeadSelectError: require("../../assets/imgs/arrowHeadSelectError.svg"),
      // itemSelected: [],
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
      prefijoI18n: "",
      variable: "",
      textOpcionSelected: "",
      primera_carga: true,
    };
  },
  computed: {
    ...mapState([]),

    fireRefresh() {
      let aux = false;
      if (this.elementosDom.fireChange) {
        aux = true;
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([""]),

    cambiarType(evt) {
      if (this.stateInput != "noEditable") {
        let evento = evt.type;
        if (evento == "focus") {
          this.stateInput = "onFocus";
          this.inFocus = true;
        } else if (evento == "blur") {
          this.stateInput = "post";
          this.inFocus = false;
          this.cambioEstado = false;
        }
      }
    },
    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },

    llamarCambioElemento(data) {
      // data.indiceElementoDom = this.indiceElementoDom;
      data.clasetienedependiente = this.elementosDom.clasetienedependiente;
      // console.log(data);
      if (!this.primera_carga) {
        this.$emit("llamarCambioElemento", data);
      } else if (
        //|| this.registrando
        this.elementosDom.variable == "tipo_documento_id" ||
        this.elementosDom.variable == "region"
      ) {
        // console.log(
        //   "disparando emit en primera carga",
        //   this.elementosDom.variable
        // );
        // || this.elementosDom.variable == "forma_entrega_id"
        this.$emit("llamarCambioElemento", data);
      }
      this.call_valida_basico();
    },

    actualizarDatos(valor) {
      let index = this.elementosDom.elementos.findIndex(
        (item) => item.value == valor
      );
      if (index > -1) {
        this.textOpcionSelected = this.elementosDom.elementos[index].text;
      }

      this.cambioEstado = !this.cambioEstado;
      this.variable = valor;
      this.llamarCambioElemento({
        elemento: `slct-${this.elementosDom.nombre_elemento}`,
        valor: this.variable,
        accion: "change",
        tipo_persona: this.elementosDom.tipo_persona,
        evento: 1,
      });
    },

    marcarSelected() {
      let index = this.elementosDom.elementos.findIndex(
        (item) => item.selectTxt == "selected"
      );

      if (index > -1) {
        this.variable = this.elementosDom.elementos[index].value;
        this.textOpcionSelected = this.elementosDom.elementos[index].text;

        this.llamarCambioElemento({
          elemento: `slct-${this.elementosDom.nombre_elemento}`,
          valor: this.variable,
          accion: "change",
          tipo_persona: this.elementosDom.tipo_persona,
          evento: 2,
        });
      } else {
        this.variable = "";
        this.textOpcionSelected = "";

        this.llamarCambioElemento({
          elemento: `slct-${this.elementosDom.nombre_elemento}`,
          valor: this.variable,
          accion: "change",
          tipo_persona: this.elementosDom.tipo_persona,
          evento: 3,
        });
      }
    },
    call_valida_basico() {
      this.$emit("valida_basico_", {
        variable: this.elementosDom.nombre_elemento,
        valida: this.variable != "" ? true : false,
      });
    },
  },
  watch: {
    fireRefresh() {
      if (this.fireRefresh) {
        this.marcarSelected();
      }
    },

    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
      // console.log(this.error);
    },
    elementosDom() {
      if (this.elementosDom.elementos.length == 1) {
        this.variable = this.elementosDom.elementos[0].value;
        this.textOpcionSelected = this.elementosDom.elementos[0].text;

        this.llamarCambioElemento({
          elemento: `slct-${this.elementosDom.nombre_elemento}`,
          valor: this.variable,
          accion: "change",
          tipo_persona: this.elementosDom.tipo_persona,
          evento: 4,
        });
        this.stateInput = "post";
      }
    },
  },
  mounted() {
    // console.log(this.elementosDom);
    if (this.elementosDom.editable == "no") {
      this.stateInput = "noEditable";
    }
    this.setPrefijoI18n();

    let index = this.elementosDom.elementos.findIndex((item) => {
      return item.selectTxt == "selected";
    });

    if (index > -1) {
      this.variable = this.elementosDom.elementos[index].value;
      this.textOpcionSelected = this.elementosDom.elementos[index].text;

      this.llamarCambioElemento({
        elemento: `slct-${this.elementosDom.nombre_elemento}`,
        valor: this.variable,
        accion: "change",
        tipo_persona: this.elementosDom.tipo_persona,
        evento: 4,
      });
      if (this.elementosDom.editable == "no") {
        this.stateInput = "noEditable";
      } else {
        this.stateInput = "post";
      }
    } else if (this.elementosDom.elementos.length == 1) {
      this.variable = this.elementosDom.elementos[0].value;
      this.textOpcionSelected = this.elementosDom.elementos[0].text;

      this.llamarCambioElemento({
        elemento: `slct-${this.elementosDom.nombre_elemento}`,
        valor: this.variable,
        accion: "change",
        tipo_persona: this.elementosDom.tipo_persona,
        evento: 4,
      });
      if (this.elementosDom.editable == "no") {
        this.stateInput = "noEditable";
      } else {
        this.stateInput = "post";
      }
    }
    setTimeout(() => {
      this.primera_carga = false;
    }, 1000);
    this.call_valida_basico();
  },
  ionViewWillEnter() {
    // console.log("#####################");
  },
};
</script>

<style >
/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
/* .selectBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.selectCustom {
  width: 100%;
  /* border-radius: 5px; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;

  z-index: 100;
}

.selectCustom.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectCustom.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.tituloCustomSelect {
  font-family: "Roboto";
  /* font-style: normal;
  font-weight: 500;
  line-height: 11px; */
  /* color: var(--customG-787878); */
  /* font-weight: 600;
  font-size: 0.5625rem; */
}

/* .opciones {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 300;
  width: 101%;
  display: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: absolute;
  margin-top: 1.25rem;
  margin-left: -5px;
} */

/* .opcionesCont {
  background-color: #fff;
  overflow-y: auto;
  max-height: 150px;
}
.opcionesCont::-webkit-scrollbar {
  width: 4px;
  height: 90%;
  max-height: 90%;
  margin-top: auto;
  margin-bottom: auto;
}
.opcionesCont::-webkit-scrollbar-track {
  background-color: var(--customF-C4C4C4);
  border-radius: 10px;
}
.opcionesCont::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--customA-801B1C);
  max-height: 2rem;
} */

.opciones.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

/* .contenido-opcion {
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s ease all;
  cursor: pointer;
} */

.placeHolderCustomSelect {
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5625rem;
  line-height: 11px; */
  color: var(--customG-787878);
}
.contenido-select {
  display: flex;
  justify-content: center;
}
</style>