<template>
  <div>
    <moreContent
      :class="{
        moreCont_sup: tipo == 'sup',
        moreCont_inf: tipo == 'inf',
        moreCont_out: scroll_to_,
      }"
    />
  </div>
</template>


<script>
import moreContent from "./moreContent.vue";

export default {
  props: {
    scroll_to_: Boolean,
    tipo: String,
  },
  components: {
    moreContent,
  },
};
</script>


<style>
.moreCont_sup {
  height: 0.9rem;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  transform: rotate(180deg);
  transition: opacity 1s ease;
  opacity: 1;
}
.moreCont_inf {
  height: 0.9rem;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  transition: opacity 1s ease;
  opacity: 1;
}

.moreCont_out {
  opacity: 0;
}
</style>