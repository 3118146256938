<template>
    <div class="enviosSegurosHomeCont">
      <div class="enviosSegurosHomeContModal">
        <div>
          <headerCerrar @cancelar="cerrarModal" colorIcon="rojoOscuro" />
        </div>

        <div class="enviosSegurosHomeContModalTitle title">
          {{ t("app.comunes.envios_seguros.tit") }}
        </div>

        <div class="enviosSegurosHomeContModalText">
          {{ t("app.comunes.envios_seguros.text") }}
        </div>
      </div>
    </div>
</template>


<script>
import { modalController } from "@ionic/vue";
import headerCerrar from "../../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "registroDisclaimer",
  components: {
    headerCerrar,
  },
  data() {
    return {
      whatsApp: require("../../assets/imgs/whatsAppTelef.svg"),
    };
  },
  computed: {},
  methods: {
    changeStateChekLocal(estado) {
      this.aceptoCondiciones = estado;
      this.checkBoxError = false;
    },
    cerrarModal() {
      modalController.dismiss({ dismissed: true, registrar: false });
    },
    // async presentAlert() {
    //   let alert = await alertController.create({
    //     // header: this.title,
    //     subHeader: this.subTitle,
    //     backdropDismiss: false,
    //     buttons: [
    //       {
    //         text: `Ok`,
    //         role: "cancel",
    //         cssClass: "regisDisclAlertBtn",
    //       },
    //     ],
    //     cssClass: "regisDisclAlert",
    //   });
    //   await alert.present();
    // },
    registrar() {
      if (!this.aceptoCondiciones) {
        // this.checkBoxError = true;
        // this.subTitle = "Debes leer y aceptar términos y condiciones.";
        // this.presentAlert();
      } else {
        // this.checkBoxError = false;
        modalController.dismiss({ dismissed: true, registrar: true });
      }
    },
    ActionLocal() {
      if (!this.aceptoCondiciones) {
        this.subTitle = "Debes leer y aceptar términos y condiciones.";
        this.presentAlert();
      } else {
        this.$router.push({ name: "registro" });
      }
    },
  },
};
</script>

<style>
.enviosSegurosHomeCont {
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0 1.68rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.enviosSegurosHomeContModal {
  /* display: block; */
  background-color: #ffffff;
  border-radius: 20px;
  /* width: 85%;
  max-width: 85%; */
  /* margin: 15% auto auto auto; */

  /* width: 86%; */
  height: fit-content;
  /* margin: 15% 0 auto 7%; */

  padding: 1rem 1.5rem 2rem 1.5rem;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.enviosSegurosHomeContModalTitle {
  color: var(--customA-801B1C);
  margin-top: 1rem;
  overflow: hidden;
}
.enviosSegurosHomeContModalText {
  margin-top: 1rem;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 18px;
  color: var(--customG-787878);
  text-align: justify;
}


/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px){
  .enviosSegurosHomeCont{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>