<template>
  <div>
    <div class="entradaLabelCont">
      <span v-show="variable != ''">{{
        t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )
      }}</span>
    </div>
    <div
      class="caja_entrada_big"
      :class="
        stateInput == 'noEditable'
          ? 'noEditable'
          : stateInput == 'inicio'
          ? 'inicio'
          : stateInput == 'onFocus'
          ? 'onFocus'
          : stateInput == 'post'
          ? 'post'
          : stateInput == 'onError'
          ? 'onError'
          : ''
      "
    >
      <!-- t(`variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`) -->
      <textarea
        class="inputDinamicInputBox caja_entrada_text_big"
        :class="stateInput == 'onError' ? 'error' : ''"
        :placeholder="`${t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )}*`"
        v-model="variable"
        name="Text1"
        cols="40"
        rows="5"
        maxlength="200"
        @focus="cambiarType($event)"
        @blur="cambiarType($event)"
        @keyup="call_valida_basico()"
        @click="call_explicacion()"
      ></textarea>

      <!-- <input
        :type="elementosDom.tipo"
        class="inputDinamicInputBox caja_entrada_text"
        :class="stateInput == 'onError' ? 'error' : ''"
        :placeholder="`${t(
          `variables.reglasFormulario['${prefijoI18n}${elementosDom.variable}']`
        )}*`"
        v-model="variable"
        @keypress="isRut($event)"
        autocomplete="off"
        @focus="cambiarType($event)"
        @blur="cambiarType($event)"
        :ref="elementosDom.variable == 'numero_documento' ? 'rut' : ''"
        :disabled="elementosDom.editable == 'no'"
        @keyup="call_valida_basico()"
      /> -->
    </div>
    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{
          variable == "" ? mensajeError[0] : elementosDom.texto_ayuda
        }}</span>
      </div>
      <div
        :class="stateInput == 'onFocus' ? 'mensajeOkFocus' : 'mensajeOkBlur'"
        v-show="mensajeOk.mostrar"
      >
        {{ mensajeOk.texto }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    typeInput: { type: String },
    mensajeError: { type: Array },
    mensajeOk: { type: Object },
    error: { type: Boolean },
    elementosDom: { type: Object },
    indiceElementoDom: { type: Number },
  },
  data() {
    return {
      variable: "",
      // typeInputLocal: "text",
      stateInput: "inicio",
      ojo: require("../../assets/imgs/ojo.svg"),
      ojoCerrado: require("../../assets/imgs/ojoCerrado.svg"),
      interactuado: false,
      textoPassVisible: false,
      inFocus: false,
      prefijoI18n: "",
      getting_foco:false
    };
  },
  methods: {
    ...mapActions(["validarRut"]),

    isRut(evt) {
      if (
        this.elementosDom.variable == "numero_documento" &&
        this.stateInput != "noEditable"
      ) {
        evt = evt ? evt : window.event;
        let caracter = String.fromCharCode(evt.which);
        let search = /[0-9kK-]/;
        if (caracter.match(search)) {
          return true;
        } else {
          evt.preventDefault();
        }
        if (this.variable == "-") {
          this.variable = "";
        }
      }
    },

    async cambiarType(evt) {
      
      let evento = evt.type;
      if (this.stateInput != "noEditable") {
        if (!this.textoPassVisible) {
          if (this.elementosDom.variable == "numero_documento") {
            if (evento == "blur") {
              this.variable = this.variable.replaceAll(".", "");
              this.variable = this.variable.replaceAll("-", "");
              let numChar = this.variable.length;
              let rut = this.variable.substring(0, numChar - 1);
              let digito = this.variable.substring(numChar - 1);
              this.variable = `${rut}-${digito}`;
              if (this.variable == "-") {
                this.variable = "";
              }
            }
          }
        }

        if (evento == "focus") {
          this.getting_foco=true;
          setTimeout(() => {
            this.getting_foco=false;
          }, 150);
          this.stateInput = "onFocus";
          this.inFocus = true;
        } else if (evento == "blur") {
          this.inFocus = false;
          if (this.error) {
            this.stateInput = "onError";
          } else {
            this.stateInput = "post";
          }

          let data = {
            elemento: this.elementosDom.identificador,
            accion: "noImporta",
            tipo_persona: this.elementosDom.tipo_persona,
          };

          data.indiceElementoDom = this.indiceElementoDom;
          data.clasetienedependiente = "";
          data.valor = this.variable;
          await this.$emit("llamarCambioElementoInput", data);
        }

        this.interactuado = true;
      }
    },

    async call_explicacion(){
      if(this.getting_foco){
        await this.$emit("call_explicacion", "direccion");
      }
    },

    setPrefijoI18n() {
      if (this.elementosDom.tipo_persona == "Remitente") {
        this.prefijoI18n = "Remitente_";
      } else if (this.elementosDom.tipo_persona == "Destinatario") {
        this.prefijoI18n = "Destinatario_";
      }
    },
    call_valida_basico() {
      this.$emit("valida_basico_", {
        variable: this.elementosDom.identificador,
        valida: this.variable != "" ? true : false,
      });
    },
  },
  watch: {
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
      // }
    },
  },
  created() {},
  mounted() {
    this.setPrefijoI18n();

    if (this.elementosDom.tipo == "number") {
      this.variable = parseInt(this.elementosDom.valor);
    } else {
      this.variable = this.elementosDom.valor;
    }
    if (this.variable != "") {
      this.stateInput = "post";
    }
    if (this.elementosDom.editable == "no") this.stateInput = "noEditable";
    // console.log(this.elementosDom);
    this.call_valida_basico();
  },
};
</script>

<style >
/* .inputBoxCompLabelCont {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  color: var(--customG-787878);
  padding-left: 0.3rem;
  min-height: 9px;
} */

.ojo {
  width: 1rem;
  display: flex;
  justify-content: center;
}

.caja_entrada_big {
  height: 5rem;
  border-radius: 10px;
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  position: relative;
}
.caja_entrada_text_big {

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.5px;
}
/* .centroAyudaHablemosMsg {
  margin-top: 0.9375rem;
  border-radius: 10px;
  padding: 0.9375rem 0.875rem 0.9375rem 0.875rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #787878;
}
.centroAyudaHablemosMsg textarea {
  border: none;
  background: transparent;
}
.centroAyudaHablemosMsg textarea:focus {
  outline: none;
} */
</style>